import React, { useRef, useState } from "react";
import axios from "axios";
import "./App.scss";
import current from "./images/current.svg";
import contact from "./images/contact.svg";
import havens from "./images/havens.png";
import golde from "./images/golde.png";
import matchaful from "./images/matchaful.png";
import upwards from "./images/upwards.png";
import jody from "./images/jody.png";
import graze from "./images/graze.svg";
import pottery from "./images/pottery.jpg";
import havens3 from "./images/havens3.jpg";
import indieBio from "./images/indieBio.png";
import spilledMatcha from "./images/spilledMatcha.png";
import upwardsGreens from "./images/upwardsGreens.png";
import golde4 from "./images/golde4.jpg";
import greatJones from "./images/greatJones.jpg";
import greatJonesLogo from "./images/greatJones.png";
import kelp from "./images/kelp.jpg";
import akua from "./images/akua.png";
import wolfRosePic from "./images/wolfRose.png";
import wsLogo from "./images/wslogo.png";
import ws2 from "./images/ws.jpg";
import chill from "./images/chill.jpg";
import body from "./images/body.jpg";
import chill2 from "./images/chill2.jpg";

const Link = ({ image, linkText, linkHref, text, images }) => (
  <div className="link">
    {images && (
      <section className="images">
        {images.map((image) => (
          <img key={image} src={image} alt={image} />
        ))}
      </section>
    )}
    <a href={linkHref} className="brand">
      <img src={image} alt={linkText} />
      <span>{text}</span>
    </a>
  </div>
);

function App() {
  const grazeRef = useRef();
  const aboutRef = useRef();
  const wolfRoseRef = useRef();

  const navItems = ["graze", "Wolf Rose", "About"];
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    email: "",
    message: "",
    name: "",
  });

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        email: "",
        name: "",
        message: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/f/mrgrppbk",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };

  function visit(item) {
    let scrollPosition;
    switch (item) {
      case "graze":
        scrollPosition =
          grazeRef.current.getBoundingClientRect().top -
          90 +
          window.pageYOffset;
        window.scroll({ top: scrollPosition, left: 0, behavior: "smooth" });
        break;
      case "Wolf Rose":
        scrollPosition =
          wolfRoseRef.current.getBoundingClientRect().top +
          90 +
          window.pageYOffset;
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: "smooth",
        });
        break;
      case "About":
        scrollPosition =
          aboutRef.current.getBoundingClientRect().top -
          90 +
          window.pageYOffset;
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: "smooth",
        });
        break;
      default:
        break;
    }
  }

  return (
    <section className="app">
      <span className="bg" />
      <nav>
        <ul>
          {navItems.map((item) => (
            <li key={item} onClick={() => visit(item)}>
              {item}
            </li>
          ))}
        </ul>
      </nav>

      <main>
        <header>
          <h1>Jody Kozlow Gardner</h1>
          <p>
            <span>Investor + Brand Strategist</span>
          </p>
        </header>
        <section className="textSection second" ref={grazeRef}>
          <h2>
            <img className="grazeLogo" src={graze} alt="graze" /> Early and
            mid-stage food investing
          </h2>
          <div className="grazeSection">
            <p>
              With a passion for start-ups, Jody founded Graze in early 2019 to
              focus on seed and early growth funding in the food space. Working
              primarily with woman-owned businesses, Graze focuses on brands
              that embody the perfect balance of the right product + the right
              design, backed up by a dedication to sustainability. As an
              investor, Jody offers expertise gained from creating & later
              selling her own fashion start-up. That experience, combined with
              her expertise in food & nutrition, offers founders the ability to
              collaborate on branding, growth strategies, design & retail in the
              plant-based food and wellness arena.
            </p>
            <p>
              Current portfolio includes: Matchaful, Havens Kitchen, Golde,
              Wooden Spoon, Great Jones, AKUA kelp burgers, Upward Farms,
              Chillhouse & BODY vodka.
            </p>

            <div className="innerLeft">
              <ul>
                <Link
                  image={matchaful}
                  linkText="Matchaful"
                  linkHref="https://www.matchaful.com/"
                  text="Investor & Brand Strategist"
                  images={[spilledMatcha]}
                />
                <Link
                  image={havens}
                  linkText="Haven's kitchen sauces"
                  linkHref="https://havenskitchen.com/"
                  text="Investor"
                  images={[havens3]}
                />
                <Link
                  image={upwards}
                  linkText="Upward farms"
                  linkHref="https://eatupwardfarms.com/"
                  text="Investor"
                  images={[upwardsGreens]}
                />
                <Link
                  image={golde}
                  linkText="GOLDE"
                  linkHref="https://golde.co/"
                  text="Investor"
                  images={[golde4]}
                />
                <Link
                  image={greatJonesLogo}
                  linkText="Great Jones Cookware"
                  linkHref="https://greatjonesgoods.com/"
                  text="Investor"
                  images={[greatJones]}
                />
                <Link
                  image={wsLogo}
                  linkText="Wooden Spoon Herbs"
                  linkHref="https://woodenspoonherbs.com/"
                  text="Investor & Brand Strategist"
                  images={[ws2]}
                />
                <Link
                  image={akua}
                  linkText="Akua Kelp Burgers"
                  linkHref="https://akua.co/"
                  text="Investor"
                  images={[kelp]}
                />
                <Link
                  image={chill2}
                  linkText="Chillhouse"
                  linkHref="https://chillhouse.com/"
                  text="Investor"
                  images={[chill]}
                />
                <div className="link">
                  <section className="images">
                    <img src={body} alt="BODY vodka" />
                  </section>
                  <div className="brand">
                    <span>Investor</span>
                  </div>
                </div>
                <Link
                  image={akua}
                  linkText="Akua Kelp Burgers"
                  linkHref="https://akua.co/"
                  text="Investor"
                  images={[kelp]}
                />
                <Link
                  image={indieBio}
                  linkText="Indie Bio: Biotech Accelerator"
                  linkHref="https://indiebio.co/"
                  text="Indie Bio: Biotech Accelerator Mentor"
                />
                <div ref={wolfRoseRef}>
                  <Link
                    image={wolfRosePic}
                    linkText="Handmade pottery: Wolf Rose"
                    linkHref="https://www.wolfrosestudio.com/"
                    text="Ceramicist"
                    images={[pottery]}
                  />
                </div>
              </ul>
            </div>
          </div>
        </section>

        <section className="textSection">
          <header>
            <p>
              <span>About</span>
            </p>
          </header>
          <p className="intro" ref={aboutRef}>
            Jody spent the early part of her career in fashion where she
            co-founded Belly Basics. Based on a little white box with four black
            pieces called The Pregnancy Survival Kit, Belly Basics
            revolutionized the maternity scene and quickly rocketed to fame as a
            global brand and industry leader. The Pregnancy Survival Kit was
            sold in department stores on four continents. The collection
            expanded to include a full collection of clothing, accessories and
            beauty. Jody co-authored the definitive book in style{" "}
            <i>Pregnancy Chic</i> which was published by Random House.
            <br />
            <br />
            Plant-based healthy eating has been a lifelong commitment. She
            enrolled in NYU's prestigious Food Studies & Nutrition graduate
            program in 2016 and founded Graze Investing in 2019.
            <br />
            <br />
            Jody received her MA in Food & Nutrition from NYU in May 2020 and
            her BA from The University of Michigan in Ann Arbor.
          </p>
        </section>
        <section className="textSection third">
          <div className="innerSections">
            <div className="innerLeft">
              <span>
                <img src={current} alt="Arrow" />
                Plant based gospel:
              </span>
              <ul>
                <li>
                  - don’t get stuck labeling your diet.  It’s far too
                  restricting.  We are all one big science experiment anyway.
                </li>
                <li>
                  -my favorite restaurants are actually steakhouses; the sides
                  are fantastic.
                </li>

                <li>-protein is easy to find, so stop worrying about it.</li>

                <li>-juice cleanses are mostly unnecessary.</li>

                <li>-raw veggies are just too hard to digest.</li>

                <li> -salads are ok at lunch; very unfulfilling at dinner</li>

                <li> -bread & pasta are a must in a plant-based diet </li>

                <li> -organic, pasture-raised eggs can be hugely satisfying</li>

                <li>
                  {" "}
                  -eating plant-based may/may not save your life, but it will
                  save our planet.
                </li>
              </ul>
            </div>
            <div className="innerRight">
              <p>I'm a plant-based eater that hates the label vegan.</p>
              <p>
                Though plant-based everywhere & anywhere, I favor the vibe of a
                place first & foremost. Usually, this means picking a spot with
                the right energy, whether it's veg friendly or not, and taking
                it from there. You'd be surprised at what you can find. Veg out
                anywhere.
                <a href="https://www.instagram.com/p/B423Z-EJsEi/">
                  Instagram: @veg_out_nyc
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="textSection final">
          <div>
            <h3>Get in touch</h3>
            <form onSubmit={handleOnSubmit}>
              <label>
                Name:
                <input
                  required
                  onChange={handleOnChange}
                  id="name"
                  value={inputs.name}
                />
              </label>
              <label>
                Email:
                <input
                  required
                  onChange={handleOnChange}
                  id="email"
                  value={inputs.email}
                />
              </label>
              <label>
                {" "}
                Message:
                <textarea
                  required
                  onChange={handleOnChange}
                  id="message"
                  value={inputs.message}
                />
              </label>
              <button
                type="submit"
                disabled={status.submitting || status.submitted}
              >
                {!status.submitting
                  ? !status.submitted
                    ? "send"
                    : "sent"
                  : "sending..."}
              </button>
            </form>
          </div>
          <div className="contact">
            <div className="flex">
              <img className="profile" src={jody} alt="Jody Kozlow Gardner" />
              <div>
                <h4>
                  Contact info <img src={contact} alt="arrow" />
                </h4>
                <p>Jody Kozlow Gardner</p>
                <p>
                  <a href="https://www.linkedin.com/in/jodykgardner/">
                    LinkedIn
                  </a>
                </p>
                <p> New York, NY</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
}

export default App;
